import React from "react"
import "normalize.css"
import { ThemeProvider } from "@material-ui/core/styles"
import theme from "./Theme"
import Footer from './Footer'

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      {children}
      <Footer />
    </ThemeProvider>
  )
}

export default Layout
