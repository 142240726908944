import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Divider from '@material-ui/core/Divider'
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import FacebookIcon from "@material-ui/icons/Facebook"
// import PinterestIcon from "@material-ui/icons/Pinterest"
import IphoneIcon from "@material-ui/icons/PhoneIphone"
import MapIcon from "@material-ui/icons/Room"
import EmailIcon from "@material-ui/icons/Email"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { OutboundLink } from "gatsby-plugin-google-gtag"

const useStyles = makeStyles(theme => ({
  footer: {
    // backgroundColor: "rgba(8,191,234, .6)",
    backgroundColor: "#1a1a1a",
    width: "100%",
    zIndex: 1299,
    position: "relative",
  },
  copyrightText: {
    fontSize: ".95rem",
    // color: theme.palette.common.muted,
    color: "#d7dadc",
    fontWeight: 500,
    "& a": {
      textDecoration: "none",
      color: '#d7dadc',
    },
  },
  snappyText: {
    fontSize: ".87rem",
    color: "#9f9f9f",
    fontWeight: 500,
  },
  itemHeaderText: {
    fontSize: "1.40625rem",
    fontFamily: "Open Sans, sans-serif, roboto",
    fontWeight: 500,
    textTransform: "uppercase",
    // color: theme.palette.common.muted,
    color: theme.palette.secondary.light,
    marginBottom: ".5em",
  },
  itemBodyText: {
    // color: theme.palette.common.muted,
    color: "#d7dadc",
    paddingTop: ".25em",
  },
  ico: {
    // color: theme.palette.common.muted,
    // color: "#ffffff",
    color: theme.palette.secondary.light,
    verticalAlign: "sub",
    marginRight: 5,
  },
  icoBtn: {
    zIndex: 1403,
    "& svg": {
      color: theme.palette.common.orange,
    },
    transition: "1s",
    "&:hover": {
      // color: "#ffffff",
      backgroundColor: theme.palette.common.muted,
      boxShadow: `0px 0px 12px 1px ${theme.palette.secondary.light}`,
      // color: theme.palette.common.orange,
      "& svg": {
        // color: theme.palette.common.orange,
        // color: "#ffffff",
      },
    },
  },
  socialIco: {
    // color: theme.palette.common.muted,
    color: "#ffffff",
    zIndex: 1402,
  },
  socialLinkCtr: {
    paddingTop: "1em",
    width: "25%",
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "40%",
    },
  },
  reviewBtn: {
    // backgroundColor: theme.palette.common.muted,
    // backgroundColor: '#1A1A1A',
    backgroundColor: theme.palette.secondary.main,
    color: "#FFFFFF",
    marginTop: "1rem",
    marginBottom: "1rem",
    padding: ".75em 3em",
    transition: "0.5s",
    "&:hover": {
      backgroundColor: theme.palette.common.muted,
      // boxShadow: `0px 0px 21px 1px ${theme.palette.common.white}`,
      boxShadow: `0px 0px 12px 1px ${theme.palette.secondary.light}`,
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "3em",
    },
  },
  licenseTxt: {
    // marginTop: '1rem',
    marginTop: ".15rem",
    opacity: ".8",
  },
  smallDivider: {
    marginLeft: "auto",
    marginRight: "auto",
    position: "relative",
    width: "10%",
    backgroundColor: theme.palette.secondary.main,
    marginTop: "1rem",
  },
}))

const Footer = () => {
  const { site } = useStaticQuery(query)

  const { title, phone, streetAddress, cityStateZip, email } = site.siteMetadata

  const classes = useStyles()
  const theme = useTheme()

  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"))

  const handleSocial = e => {
    e.preventDefault()
    switch (e.currentTarget.id) {
      case "facebook":
        window.open("https://www.facebook.com/andreattawaterscapes", "_blank")
        break
      case "pinterest":
        window.open("https://www.pinterest.com/andreattaponds/", "_blank")
        break
      default:
        break
    }
  }
  return (
    <footer className={classes.footer}>
      <Container maxWidth="md" style={{ padding: "6.25em 0" }}>
        <Grid container direction="row">
          <Grid item xs={12}>
            <Grid container direction="row" justifyContent="space-evenly">
              <Grid
                item
                xs={12}
                md={3}
                style={{ marginTop: matchesSM ? "1.5em" : undefined }}
              >
                <Grid container direction="row">
                  <Grid item xs={12}>
                    <Grid container justifyContent="center">
                      <IphoneIcon fontSize="large" className={classes.ico} />
                      <span className={classes.itemHeaderText}>Call</span>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      align="center"
                      className={classes.itemBodyText}
                    >
                      <a
                        href={`tel:${phone}`}
                        style={{ textDecoration: "none" }}
                        className={classes.itemBodyText}
                      >
                        {phone}
                      </a>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                style={{ marginTop: matchesSM ? "1.5em" : undefined }}
              >
                <Grid container direction="row">
                  <Grid item xs={12}>
                    <Grid container justifyContent="center">
                      <MapIcon fontSize="large" className={classes.ico} />
                      <span className={classes.itemHeaderText}>Address</span>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      align="center"
                      className={classes.itemBodyText}
                    >
                      {streetAddress}
                    </Typography>
                    <Typography
                      variant="body1"
                      align="center"
                      className={classes.itemBodyText}
                    >
                      {cityStateZip}
                    </Typography>
                    <Typography
                      variant="body1"
                      align="center"
                      className={classes.itemBodyText}
                    >
                      By Appointment Only
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                style={{ marginTop: matchesSM ? "1.5em" : undefined }}
              >
                <Grid container direction="row">
                  <Grid item xs={12}>
                    <Grid container justifyContent="center">
                      <EmailIcon fontSize="large" className={classes.ico} />
                      <span className={classes.itemHeaderText}>Email</span>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      align="center"
                      className={classes.itemBodyText}
                    >
                      <a
                        href={`mailto:${email}`}
                        style={{ textDecoration: "none" }}
                        className={classes.itemBodyText}
                      >
                        {email}
                      </a>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider className={classes.smallDivider} />
            <Typography align='center' variant='subtitle1' className={classes.licenseTxt}>
              CCB# 136062
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {/* Social Links */}
            <Grid
              container
              // justifyContent="space-evenly"
              justifyContent="center"
              alignItems="center"
              className={classes.socialLinkCtr}
            >
              <Grid item>
                <Grid container justifyContent="center">
                  <IconButton
                    aria-label="facebook link"
                    className={classes.icoBtn}
                    onClick={handleSocial}
                    id="facebook"
                    style={{ marginLeft: "auto" }}
                  >
                    <FacebookIcon
                      fontSize="large"
                      id="Facebook Icon"
                      className={classes.socialIco}
                    />
                  </IconButton>
                </Grid>
              </Grid>
              {/* <Grid item xs={6}>
                <Grid container justifyContent="center">
                  <IconButton
                    aria-label="pinterest link"
                    className={classes.icoBtn}
                    onClick={handleSocial}
                    id="pinterest"
                    style={{ marginRight: "auto" }}
                  >
                    <PinterestIcon
                      id="Pinterest Icon"
                      fontSize="large"
                      className={classes.socialIco}
                    />
                  </IconButton>
                </Grid>
              </Grid> */}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <Button
                variant="contained"
                className={classes.reviewBtn}
                size="medium"
                component={Link}
                to="/review-us/"
              >
                Review Us!
              </Button>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              position: "absolute",
              bottom: 15,
              marginLeft: "auto",
              marginRight: "auto",
              left: 0,
              right: 0,
            }}
          >
            <Typography
              variant="subtitle1"
              align="center"
              className={classes.copyrightText}
            >
              {/* Copyright © {new Date().getFullYear()} {title} */}
              Copyright © {new Date().getFullYear()} <Link to='/' activeStyle={{color: '#d7dadc'}}>Andreatta Waterscapes</Link>
              <br />
              <OutboundLink
                href="https://snappywebdesign.net/"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
                className={classes.snappyText}
              >
                Snappy Web Design
              </OutboundLink>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </footer>
  )
}

Footer.propTypes = {
  title: PropTypes.string,
}

export default Footer

const query = graphql`
  {
    site {
      siteMetadata {
        title
        phone
        streetAddress
        cityStateZip
        email
      }
    }
  }
`
