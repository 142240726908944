import { createTheme } from "@material-ui/core/styles"

const mainBlue = "#08BFEA"
const mainOrange = "#D14F03"
const mutedDark = "#29292a"

export default createTheme({
  palette: {
    type: "dark",
    common: {
      blue: mainBlue,
      orange: mainOrange,
      muted: mutedDark,
    },
    primary: {
      main: mainBlue,
      // 'light', 'dark', and 'contrastText' are calculated to contrast with palette.primary.main.
    },
    secondary: {
      main: mainOrange,
      dark: "#991A00",
    },
    background: {
      // default: "#1A1A1A",
      default: "#2c2c30",
      // paper: "#1c1c1e",
      paper: "#36363b",
    },
    text: {
      primary: "#d7dadc",
    },
  },
  typography: {
    title: {
      fontWeight: 700,
      fontSize: "5rem",
      lineHeight: "5rem",
      textTransform: "uppercase",
      letterSpacing: "1rem",
    },
    subtitle: {
      fontWeight: 400,
      fontSize: "2rem",
      lineHeight: "2rem",
    },
    sectionHeading: {
      textTransform: "capitalize",
      fontFamily: "roboto",
      fontSize: "3rem",
      fontWeight: 500,
      lineHeight: 1.2,
    },
    sectionSubheading: {
      fontFamily: "roboto",
      fontSize: "1.25rem",
      lineHeight: 1.5,
      color: "#212529",
      fontWeight: 300,
    },
    body1: {
      fontFamily: "Open Sans, sans-serif",
      fontSize: ".96575rem",
    },
    body2: {
      fontFamily: "Lora, Work Sans, sans-serif",
    },
    tab: {
      // color: mutedDark,
      opacity: 0.9,
      fontSize: ".9rem",
      fontWeight: 400,
      textTransform: "uppercase",
      fontFamily: "Work Sans, sans-serif",
      lineHeight: 1.5,
      letterSpacing: ".00938em",
    },
    h1: {
      fontFamily: "Work Sans, sans-serif",
      fontSize: "2.5rem",
      fontWeight: 400,
      lineHeight: "1.2",
      letterSpacing: 3,
      textTransform: "uppercase",
    },
    subtitle1: {
      fontFamily: "Work Sans, sans-serif",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        fontFamily: "Work Sans, sans-serif",
      },
    },
  },
})
